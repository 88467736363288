import { string } from "yup/lib/locale";

const PROFILE = "profile" as string;

export const getProfile = (): any => {
  const str = window.sessionStorage.getItem(PROFILE);
  let profile;
  if (str) {
    profile = JSON.parse(str);
  }

  return profile;
};

export const saveProfile = (WAprofile: any): void => {
  WAprofile.extension_WAClientId = WAprofile.extension_WACustomerId;
  WAprofile.Is_Selected_Customer = false;
  WAprofile.Selected_Customer_ID = WAprofile.extension_WACustomerId;
  WAprofile.Selected_Customer_CCProfileId = WAprofile.extension_ccProfileId;
  window.sessionStorage.setItem(PROFILE, JSON.stringify(WAprofile));
};
export const destroyProfile = (): void => {
  window.sessionStorage.removeItem(PROFILE);
};

export const UpdateSelectedCustomer = (
  Selected_Customer_ID: any,
  Selected_Customer_CCProfileId: any
): void => {
  const obj = getProfile();
  obj.Is_Selected_Customer = true;
  obj.Selected_Customer_ID = Selected_Customer_ID;
  obj.Selected_Customer_CCProfileId = Selected_Customer_CCProfileId;
  window.sessionStorage.setItem(PROFILE, JSON.stringify(obj));
};

export const UpdateCcProfileId = (CCProfileId: any): void => {
  const obj = getProfile();
  obj.extension_ccProfileId = CCProfileId;
  window.sessionStorage.setItem(PROFILE, JSON.stringify(obj));
};

export default {
  getProfile,
  saveProfile,
  destroyProfile,
  UpdateSelectedCustomer,
  UpdateCcProfileId,
};
