import router from "@/router";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "../config/MsalConfig";
import jwtService from "../services/JwtService";
import userAccountService from "../services/UserAccountIDService";
import handleProfileService from "../services/HandleProfileIDService";
import ApiService from "@/core/services/ApiService";
import handleProfileIDService from "../services/HandleProfileIDService";

class MsalService {
  public static login(): void {
    const msal = new PublicClientApplication(msalConfig);

    msal
      .handleRedirectPromise()
      .then((data) => {
        if (data != null) {
          jwtService.saveToken(data.idToken);
          userAccountService.saveAccountID(
            data.account == null ? "" : data.account.homeAccountId
          );
          handleProfileService.saveProfile(data.idTokenClaims);

          ApiService.get(
            "api/GetProfileReference",
            "?CustomerId=" +
              handleProfileService.getProfile().extension_WACustomerId
          )
            .then((res) => {
              if (res.statusText === "OK") {
                handleProfileIDService.UpdateCcProfileId(res.data.value);
              }
            })
            .catch((err) => {
              console.log(err);
            });

          // const userProfile = handleProfileService.getProfile();
          // if(userProfile.newUser)
          // {
          //   const config = {
          //     data: {

          //     }
          //   }
          //   ApiService.update("/api/UpdateUserRole","?UserId="+userProfile.oid+"&RoleId=11",config).then(res => {
          //     if(res.data.value === "success")
          //     {
          //       this.logout();
          //     }
          //   });
          // }
          const RoleID = handleProfileService.getProfile().extension_Role;
          if (RoleID !== "11") {
            router.push({ name: "pending-invoices" });
          } else {
            router.push({ name: "open-invoices-view" });
          }
        } else {
          msal.loginRedirect(loginRequest);
        }
      })
      .catch((error) => {
        console.log("Msal Serveice Error(Login): " + error);
      });
  }

  public static logout(): void {
    const msal = new PublicClientApplication(msalConfig);
    const accountId = userAccountService.getAccountID() || "";
    if (accountId !== null && accountId !== "" && accountId !== undefined) {
      const logoutRequest = {
        account: msal.getAccountByHomeId(accountId),
      };

      jwtService.destroyToken();
      userAccountService.destroyAccountID();
      handleProfileService.destroyProfile();
      msal
        .logoutRedirect(logoutRequest)
        .then((res) => {
          console.log("Logout Successfully: " + res);
          router.push({ name: "sign-in" });
        })
        .catch((error) => {
          console.log("Msal Serveice Error(Logout): " + error);
        });
    }
    // else{
    //   jwtService.destroyToken();
    //   userAccountService.destroyAccountID();
    //   handleProfileService.destroyProfile();
    //   router.push({name:"sign-in"});
    // }
  }
}

export default MsalService;
